
import Vue from "vue";

export default Vue.extend({
  transition: {
    afterLeave(el) {
      
    },
  },
  name: "Collection_Cases_Logos",
  props: {
    items: Object,
    itemClass: String,
    filtering: Boolean,
  },
  computed: {
    filters(): any {
      return this.$store.state.client_filters.filter;
    },
  },
  data() {
    let allFilters: any = {};

    //create unique list of tags on items to filter
    function forEach(array: any[], callback: Function) {
      for (let index = 0; index < array.length; index++) {
        callback(array[index], index, array);
      }
    }

    forEach(this.$props.items.data, async (item: { attributes: { Tags: string } }): Promise<any> => {
      if (item.attributes.hasOwnProperty("Tags")) {
        if (item.attributes.Tags !== null) {
          let tagText = item.attributes.Tags.toString();
          let tag = { name: "", value: "" };
          tag.name = tagText.replace(/_/g, " ").replace("semi", "(semi)");
          tag.value = tagText;

          allFilters[item.attributes.Tags] = tag;
        }
      }
    });

    const allFiltersArray = Object.keys(allFilters).map(
      (key) => allFilters[key]
    );

    return { allFiltersArray };
  },
  mounted() {
    this.delayAnimation();
  },
  methods: {
    delayAnimation() {
      let logos = document.querySelectorAll(".case-logo img");
      let delay = 0;
      logos.forEach((logo, index) => {
        logo.setAttribute("data-aos-delay", delay.toString());
        delay += 50;
      });
    },
    getItemClass: (
      itemStyle: String,
    ): String => {
      let itemClassString = itemStyle
        ? itemStyle
        : "col-6 col-sm-4 col-xl-2";

      return itemClassString;
    },
    setFilter(e: any, param: string) {
      e.target.dataset.filter
        ? this.$store.commit("client_filters/add", e.target.dataset.filter)
        : "Bedrijven";
    },
    shouldDisplay(e: any) {
      if (this.$store.state.client_filters.filter === "all") {
        return true;
      }
      if (e.attributes.Tags instanceof Array) {
        return false;
      }
      if (this.$store.state.client_filters.filter === e.attributes.Tags) {
        const filteredItems = this.$props.items.data.filter((item: any) => {
          return item.attributes.Tags === e.attributes.Tags;
        });
        if (process.client && window.innerWidth < 768) {
          return filteredItems.slice(0, 6).includes(e);
        }
        else{
          return filteredItems.includes(e);
        }
      }
      return false;
    },
  },
});
